import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import { ColorSettings, ColorSettingsPicker, DefaultSettingsFunction } from 'components/template-designer/types/dynamicLayer.type';
import { ColorHelpers } from 'components/template-designer/helpers/color.helpers';
import { ColorSettingsForm } from '../forms/color-settings-form';
import { baseSettings } from './base-settings';

const color: InputAttribute<DefaultSettingsFunction<ColorSettings>> = {
    label: Translation.get('dynamicLayers.labels.fontColor', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.color', 'template-designer'),
    attribute: 'color',
    settings: {
        color: (selectedLayer, attribute) => {
            const input: ColorSettings = {
                ...baseSettings,
                type: 'color',
                picker: ColorSettingsPicker.PredefinedCustom,
                colors: ColorHelpers.getBrandGuideColorsString(false),
                useAlpha: true
            };

            const colors = DynamicLayerHelpers.getDefaultColorValues(attribute, input, selectedLayer);
            input.colors = colors;

            return input;
        }
    },
    defaultSetting: 'color',
    updateSettings: (selectedLayer, attribute, settings) => DynamicLayerHelpers.updateSettingsColor(selectedLayer, attribute, settings),
    editForm: () => <ColorSettingsForm attribute={color.attribute} updateSettings={color.updateSettings} />
};

export { color };
